import { slick } from 'slick-carousel';

document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--testimonials');
	const config = { rootMargin: '0px 0px',	threshold: 0.5 };

	const block_testimonials = {

		init: (block) => {
			block_testimonials.slick(block);
		},
		
		blockIntersection: (entries) => {
			entries.forEach( entry => {
				if ( entry.intersectionRatio > 0 ) {
					blockObserver.unobserve( entry.target );
					block_testimonials.animate( entry.target );
				}
			});		
		},

		slick: (block) => {

			const target = block.querySelector('.testimonials');

			const options = {
				slidesToShow: 1,
				arrows: true,
				dots: true,
				autoplay: false,
				infinite: true,
				appendArrows: '#' + block.id + ' .testimonials-nav',
				appendDots: '#' + block.id + ' .testimonials-nav'
			};
		
			let items = target?.querySelectorAll('.testimonial');
		
			if( items && items.length > 1) {
				$(target).slick(options);
			}
		},

		animate: (block) => {
			block.classList.add('animate');
		},
		
		log: () => {
			console.log('test');
		}
	}

	let blockObserver = new IntersectionObserver( block_testimonials.blockIntersection, config );

	blocks.forEach( block => {
		block_testimonials.init(block);
		blockObserver.observe( block );
	});
});

